import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  // 登录页
  {
    path: '/',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('../views/Error.vue')

  },
 
  // 布局页
  {
    path: '/layout',
    name: 'layout',
    component: () => import('../views/Layout.vue'),
    children: [
     
      {
        path: 'userList',
        name: 'userList',
        component: () => import('../views/userManage/UserList.vue'),
        meta: {
          permissions: ['overview_user']
        }   
      },
      {
        path: 'userSetting',
        name: 'userSetting',
        component: () => import('../views/userManage/UserSetting.vue')
      },      
      {
        path: 'remarkManage',
        name: 'remarkManage',
        component: () => import('../views/userManage/RemarkManage.vue')
      },    
      {
        path: 'plantList',
        name: 'plantList',
        component: () => import('../views/plantManage/PlantList.vue'),
        meta: {
          permissions: ['overview_factory']
        }
      },
      {
        path: 'ordersAllList',
        name: 'ordersAllList',
        component: () => import('../views/ordersManage/OrdersAllList.vue'),
        meta: {
          permissions: ['overview_order']
        }
      },
      {
        path: 'deviceAllList',
        name: 'deviceAllList',
        component: () => import('../views/plantManage/DeviceAllList.vue'),
        meta: {
          permissions: ['overview_device']
        }
      },
      {
        path: 'productsList',
        name: 'productsList',
        component: () => import('../views/proDNA/ProductsList.vue'),
        meta: {
          permissions: ['overview_product']
        }
      },
      {
        path: 'productList',
        name: 'productList',
        component: () => import('../views/productManage/productList'),
        meta: {
          permissions: ['overview_product']
        }
      },
      {
        path: 'generateList',
        name: 'generateList',
        component: () => import('../views/proDNA/GenerateList.vue'),
        meta: {
          permissions: ['overview_factory']
        }
      },
      {
        path: 'corpusWare',
        name: 'corpusWare',
        component: () => import('../views/plantManage/wareManage/CorpusWare.vue'),
        meta: {
          permissions: ['overview_inventory']
        }
      },
      {
        path: 'finishedWare',
        name: 'finishedWare',
        component: () => import('../views/plantManage/wareManage/FinishedWare.vue'),
        meta: {
          permissions: ['overview_inventory']
        }
      },
      {
        path: 'warehouseList',
        name: 'warehouseList',
        component: () => import('../views/plantManage/wareManage/WarehouseList.vue'),
        meta: {
          permissions: ['overview_inventory']
        }
      },      
      {
        path: 'roleManage',
        name: 'roleManage',
        component: () => import('../views/userManage/RoleManage.vue'),
        meta: {
          permissions: ['overview_user']
        }    
      },
      {
        path: 'testFixture',
        name: 'testFixture',
        component: () => import('../views/proDNA/TestFixture.vue'),
        meta: {
          permissions: ['fixture_correction']
        }    
      },
      {
        path: 'testReport',
        name: 'testReport',
        component: () => import('../views/proDNA/TestReport.vue'),
        meta: {
          permissions: ['test_report']
        }    
      },
      {
        path: 'proofing',
        name: 'proofing',
        component: () => import('../views/other/Proofing.vue'),
        meta: {
          permissions: ['overview_user']
        }   

      },
      {
        path: 'plantDetail',
        name: 'plantDetail',
        component: () => import('../views/plantManage/PlantDetail.vue'),
        redirect: '/layout/plantDetail/largeScreen',
        meta: {
          permissions: ['overview_factory']
        },
        children: [
          {
            path: 'deviceList',
            name: 'deviceList',
            component: () => import('../views/plantManage/DeviceList.vue')
          },
          {
            path: 'dataAnalysis',
            name: 'dataAnalysis',
            component: () => import('../views/plantManage/DataAnalysis')
          },
          {
            path: 'productionLine',
            name: 'productionLine',
            component: () => import('../views/plantManage/ProductionLine')
          },
          {
            path: 'processList',
            name: 'processList',
            component: () => import('../views/plantManage/ProcessList')
          },
          {
            path: 'ordersList',
            name: 'ordersList',
            component: () => import('../views/plantManage/OrdersList')
          },
          {
            path: 'largeScreen',
            name: 'largeScreen',
            component: () => import('../views/plantManage/LargeScreen')
          }
         
        ]
      }

    ]
  }
 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {  
  console.log(to, 'to>>>>>>>>>')
  // 验证token，只有存在token时才能跳转到内容页  
  const token = sessionStorage.getItem('token')  

  if (!token && to.path !== '/login') { 
    sessionStorage.setItem('location_href', window.location.href)

    next('/login')
  } else {  
    // 判断是否有访问某个页面的权限
    const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
    const permissions = to.meta.permissions
    if (permissions && userInfo.role !== 'admin') {
      const flag = userInfo.permissions.includes(permissions[0])
      if (flag) {
        next()  
      } else {
        next('/error')
      }
    } else {
      next()  
    }
  }  
}) 

export default router
